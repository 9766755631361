import type React from 'react';

type AnniversaryEnercityLogoProps = React.SVGProps<SVGSVGElement> & {
  solidFill?: boolean;
};

const AnniversaryEnercityLogo = ({
  id = 'anniversary-logo',
  solidFill,
  ...props
}: AnniversaryEnercityLogoProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.73 191" {...props}>
      {!solidFill && (
        <defs>
          <linearGradient
            id={`${id}-gradient-1`}
            x1={400.42}
            x2={789.73}
            y1={142.34}
            y2={142.34}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="red" />
            <stop offset={1} stopColor="#909" />
          </linearGradient>
          <linearGradient
            id={`${id}-gradient-2`}
            x1={400.42}
            x2={789.73}
            y1={47.05}
            y2={47.05}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="red" />
            <stop offset={1} stopColor="#909" />
          </linearGradient>
          <linearGradient
            id={`${id}-gradient-3`}
            x1={135.93}
            x2={385.99}
            y1={88.26}
            y2={88.26}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.3} stopColor="#909" stopOpacity={0} />
            <stop offset={0.48} stopColor="#909" />
            <stop offset={0.52} stopColor="#909" />
            <stop offset={0.7} stopColor="#909" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id={`${id}-gradient-4`}
            x1={124.46}
            x2={397.47}
            y1={151.91}
            y2={24.61}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.27} stopColor="#909" stopOpacity={0} />
            <stop offset={0.46} stopColor="#909" />
            <stop offset={0.6} stopColor="#909" />
            <stop offset={0.72} stopColor="#909" stopOpacity={0} />
          </linearGradient>
          <clipPath id={`${id}-clippath`}>
            <path
              d="M385.51 83.44c-.05 8.78-.64 14.94-1.1 18.48-6.48 50.27-34.29 74.6-71.39 74.6-18.7 0-36.02-5.18-47.47-18.53-.54-.63-10-13.76-15.19-26.46-5.78-14.16-9.2-32.74-10.16-42.74l-.16-1.37c-2.16-36.42-15.51-47.09-31.36-47.09-15.2 0-29.39 9.76-31.43 42.7-.06 1.01-.11 2.05-.15 3.1-.02.42.06 3.5.09 4.05 1.96 36.13 15.93 45.2 31.5 45.2 11.22 0 21.18-5.36 26.81-21.4 1.57 8.17 4.29 16.55 8.3 25.48 3.04 6.77 7.05 13.23 10.95 18.84-12.27 11.88-27.86 17.42-46.06 17.42-39.72 0-70.98-27.47-72.75-84.56-.03-1 .06-5.47.13-6.33C139.01 26.68 168.92 0 208.69 0c20.57 0 39.18 6.79 51.08 22.75 4.75 6.37 9.94 15.09 14.68 28.19 4.48 12.41 6.01 26.08 7.04 35.63.22 2.03.65 5.2.82 6.96 3.18 32.07 16.25 41.86 31.29 41.86 14.96 0 27.69-9.5 30.89-41.17.08-.75.24-4.86.24-4.94.01-1.41-.06-3.63-.07-4.43-.31-33.53-15.82-42.99-31.06-42.99-10.95 0-21.34 5.13-27.29 21.05-.7-4.38-1.68-8.72-3.11-12.69-5.37-14.85-9.36-23.32-13.78-29.25-.58-.78-1.2-1.54-1.82-2.29 12.25-11.48 28.02-17.15 46-17.15 37.73 0 68.32 28.4 71.74 77.25.06.66.18 3.8.17 4.67ZM116.53 101.1c8.29-11.22 13.75-23.73 14.35-37.47C132.41 28.48 106.01.43 67.95.43S6.2 27.82 5.53 63c-.02.99.02 3.97.02 3.97l41.31.2.16-3.71c.56-12.71 7.02-23.27 20.92-23.2 13.9.07 20.74 10.44 20.18 23.15-.49 11.12-6.32 18.92-16.64 30.52l-6.95 7.74L0 174.97h134.57v-39.29H91.1l25.42-34.59Z"
              fill="none"
            />
          </clipPath>
          <clipPath id={`${id}-clippath-1`}>
            <path
              d="m240.2 103.44 18.09 60.68-51.01 20.06-13.54-61.4 46.46-19.34z"
              fill="none"
            />
          </clipPath>
          <clipPath id={`${id}-clippath-2`}>
            <path
              d="m258.29 13.76 29.97 60.67 46.18-8.21 3.38-79.3-79.53 26.84z"
              fill="none"
            />
          </clipPath>
        </defs>
      )}
      <g>
        <path
          d="M674.4 174.98h12.95v-57.47H674.4v57.47Zm-92.44-33.03v33.02h12.95V143.4c0-10.12 3.8-13.68 12.14-13.68.24 0 1.62 0 3 .08l.32-12.3c-.81-.08-2.27-.08-3.08-.08-18.45 0-25.33 10.2-25.33 24.52M428.1 128.76c7.53 0 12.55 4.45 13.03 12.54h-27.11c1.38-7.45 6.72-12.54 14.08-12.54m-27.68 17.48c0 17 11.98 29.38 27.68 29.38 11.82 0 19.18-4.37 25.66-12.46l-9.71-8.01c-3.08 5.18-9.79 8.58-15.95 8.58-6.8 0-13.11-5.42-14.08-12.22h40.31c.08-1.54.24-4.13.24-5.26 0-16.03-8.66-29.38-26.47-29.38-15.7 0-27.68 12.55-27.68 29.38m146.66-17.49c7.53 0 12.55 4.45 13.03 12.54H533c1.38-7.45 6.72-12.54 14.08-12.54m-27.68 17.48c0 17 11.98 29.38 27.68 29.38 11.82 0 19.18-4.37 25.66-12.46l-9.71-8.01c-3.08 5.18-9.79 8.58-15.95 8.58-6.8 0-13.11-5.42-14.08-12.22h40.31c.08-1.54.24-4.13.24-5.26 0-16.03-8.66-29.38-26.47-29.38-15.7 0-27.68 12.55-27.68 29.38m93.89-.01c0 16.51 12.87 29.38 29.38 29.38 9.87 0 18.7-4.94 24.04-12.46l-9.79-8.01c-2.91 4.69-8.09 8.01-14.25 8.01-9.55 0-16.35-7.36-16.35-16.92s6.8-16.92 16.35-16.92c6.15 0 11.33 3.32 14.25 8.01l9.79-8.01c-5.34-7.53-14.16-12.46-24.04-12.46-16.51 0-29.38 12.87-29.38 29.38m-151.27-3.96v32.7h12.95v-33.51c0-7.77 4.53-12.71 12.14-12.71s12.14 4.94 12.14 12.71v33.51h12.95v-32.7c0-13.92-7.45-25.66-25.09-25.66s-25.09 11.74-25.09 25.66m237.22-41.2v49.45c0 14.33 6.88 24.52 25.33 24.52 2.51 0 3.64 0 5.67-.16l-.32-12.3c-1.78.16-2.67.16-5.58.16-8.34 0-12.14-3.56-12.14-13.68v-19.91h25.17L754.12 174l-.65 1.46c-.97 2.18-2.1 3.4-6.64 3.4-2.59 0-5.83-.08-8.98-.41l-.32 12.14c3.56.32 6.96.41 10.6.41 10.44 0 15.7-4.29 20.48-17l21.13-56.49h-13.92l-14.41 40.87-11.25-31.97c-1.86-5.42-4.37-8.9-12.14-8.9H712.2v-16.43h-12.95Zm-18.29-7.41c-4.21 0-7.69 3.48-7.69 7.69s3.48 7.69 7.69 7.69 7.69-3.48 7.69-7.69-3.48-7.69-7.69-7.69Z"
          style={{
            fill: solidFill ? 'currentColor' : `url(#${id}-gradient-1)`,
          }}
        />
        <path
          d="M431.85 92.59c-19.68 0-31.43-11.75-31.43-31.43v-1.09h24.24v1.09c0 5.94 2.28 8.71 7.19 8.71s7.19-2.77 7.19-8.71V2.09h24.24v60.45c0 18.81-11.75 30.05-31.43 30.05Zm23.69-1.12h24.74l6.8-14.66h33.57l6.8 14.66h24.74l-48.2-89.96-48.45 89.95Zm38.66-33.3 9.8-18.55 9.79 18.55H494.2Zm144.26 33.3V1.99h-24.24v33.3h-36.99V1.99h-24.24v89.48h24.24V58.7h36.99v32.77h24.24Zm151.27.02V68.76h-38.61V56.51h30.34V37.3h-30.34V25.16h38.61V2.32h-62.85v89.17h62.85ZM668.96 41.66V24.39h11.33c5.2 0 9.12 3.74 9.12 8.7s-3.92 8.57-9.12 8.57h-11.33Zm-24.24 49.81h24.24V56.82l28.99 34.65h28.21l-29.49-32.4c10-5.28 16.28-15.45 16.28-26.81 0-16.64-13.54-30.18-30.18-30.18h-38.05v89.39Z"
          style={{
            fill: solidFill ? 'currentColor' : `url(#${id}-gradient-2)`,
          }}
        />
        <path
          d="M385.51 83.44c-.05 8.78-.64 14.94-1.1 18.48-6.48 50.27-34.29 74.6-71.39 74.6-18.7 0-36.02-5.18-47.47-18.53-.54-.63-10-13.76-15.19-26.46-5.78-14.16-9.2-32.74-10.16-42.74l-.16-1.37c-2.16-36.42-15.51-47.09-31.36-47.09-15.2 0-29.39 9.76-31.43 42.7-.06 1.01-.11 2.05-.15 3.1-.02.42.06 3.5.09 4.05 1.96 36.13 15.93 45.2 31.5 45.2 11.22 0 21.18-5.36 26.81-21.4 1.57 8.17 4.29 16.55 8.3 25.48 3.04 6.77 7.05 13.23 10.95 18.84-12.27 11.88-27.86 17.42-46.06 17.42-39.72 0-70.98-27.47-72.75-84.56-.03-1 .06-5.47.13-6.33C139.01 26.68 168.92 0 208.69 0c20.57 0 39.18 6.79 51.08 22.75 4.75 6.37 9.94 15.09 14.68 28.19 4.48 12.41 6.01 26.08 7.04 35.63.22 2.03.65 5.2.82 6.96 3.18 32.07 16.25 41.86 31.29 41.86 14.96 0 27.69-9.5 30.89-41.17.08-.75.24-4.86.24-4.94.01-1.41-.06-3.63-.07-4.43-.31-33.53-15.82-42.99-31.06-42.99-10.95 0-21.34 5.13-27.29 21.05-.7-4.38-1.68-8.72-3.11-12.69-5.37-14.85-9.36-23.32-13.78-29.25-.58-.78-1.2-1.54-1.82-2.29 12.25-11.48 28.02-17.15 46-17.15 37.73 0 68.32 28.4 71.74 77.25.06.66.18 3.8.17 4.67ZM116.53 101.1c8.29-11.22 13.75-23.73 14.35-37.47C132.41 28.48 106.01.43 67.95.43S6.2 27.82 5.53 63c-.02.99.02 3.97.02 3.97l41.31.2.16-3.71c.56-12.71 7.02-23.27 20.92-23.2 13.9.07 20.74 10.44 20.18 23.15-.49 11.12-6.32 18.92-16.64 30.52l-6.95 7.74L0 174.97h134.57v-39.29H91.1l25.42-34.59Z"
          style={{
            fill: solidFill ? 'currentColor' : 'red',
          }}
        />
        {!solidFill && (
          <g
            style={{
              opacity: 0.8,
            }}
          >
            <g
              style={{
                clipPath: `url(#${id}-clippath)`,
              }}
            >
              <g
                style={{
                  clipPath: `url(#${id}-clippath-1)`,
                }}
              >
                <path
                  d="M135.93 0h250.06v176.52H135.93z"
                  style={{
                    fill: `url(#${id}-gradient-3)`,
                  }}
                />
              </g>
              <g
                style={{
                  clipPath: `url(#${id}-clippath-2)`,
                }}
              >
                <path
                  d="M135.93 0h250.06v176.52H135.93z"
                  style={{
                    fill: `url(#${id}-gradient-4)`,
                  }}
                />
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  );
};

export default AnniversaryEnercityLogo;
